.title {
    margin-top: 3%;
    margin-left: 45%;
}

.calculatorForm {
    margin-top: 1%;
    margin-left: 40%;
}

.container {
    display: flex;
    align-items: start;
}

.left {
    width: 100px;
    display: flex;
    align-items: start;
    margin: 5px;
}

.input {
    width: 90px;
    display: flex;
    align-items: start;
    margin: 5px;
}

.inputText {
    width: 80px;
}

.selection {
    width: 180px;
    display: flex;
    align-items: start;
    margin: 5px;
}

.right {
    width: 20px;
    display: flex;
    align-items: start;
    margin: 5px;
}

.button {
    display: flex;
    align-items: start;
    margin: 5px;
}

.details {
    display: grid;
    justify-content: center;
    margin: 5px;
}

.detailTitle {
    margin-left: 35%;
    margin-top: 3px;
    margin-bottom: 3px;
}

.detailTable {
    width: 100%;
    border-collapse: collapse;
}

.detailTable th,
.detailTable td {
    border: 1px solid #333333;
}