.h1 {
    margin-top: 1.5%;
    margin-left: 5%;
}

.h2 {
    font-size: 28px;
    font-weight: bolder;
}

.h3 {
    font-size: 20px;
    margin-top: 1.2%;
    margin-bottom: 1.2%;
}

.h4 {
    font-size: 20px;
    margin-top: 0.5%;
}
